@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/c0bad8e347f78c95-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
}

@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/bfd8aa0648f4a001-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/454c62743bfc71e7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/ee7b921156e3abde-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/aa99ad84d685cb2f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__sukhumvit_be0f93';
src: url(/_next/static/media/c18ed8a99dcd7b6d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__sukhumvit_Fallback_be0f93';src: local("Arial");ascent-override: 113.30%;descent-override: 48.69%;line-gap-override: 1.03%;size-adjust: 97.35%
}.__className_be0f93 {font-family: '__sukhumvit_be0f93', '__sukhumvit_Fallback_be0f93'
}

